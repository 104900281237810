import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Drawer, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { navigate } from "gatsby";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import firstEditUser from "../../../newApi/profile/firstEditUser";
import getProfiles from "../../../newApi/profile/getProfiles";
import { notificationEmail } from "../../../newApi/user";
import sendRegisterVerificationEmail from "../../../newApi/user/sendRegisterVerificationEmail";
import {
  logoutSuccessful,
  updateUser,
} from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// markup
const SetupGoogle2FADrawer = (props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState({});
  const [forceFill, setForceFill] = useState(false);
  const [disable, setDisable] = useState(true);
  const [emailCode, setEmailCode] = useState("");
  const [codeLoading, setCodeLoading] = useState(false);
  const [codeDisabled, setCodeDisabled] = useState(false);
  const isAuthenticated = get(props.user, "isAuthenticated");
  const accessKey = get(props.user, "accessKey");
  const userId = get(props.user, "user._id");
  let user = get(props.user, "user");
  const [form] = useForm();

  // console.log("edit props", props);

  useEffect(() => {
    if (props.visible === true) {
      getProfiles(
        1,
        0,
        {
          userId: userId,
        },
        accessKey
      )
        .then((res) => {
          // console.log(res);
          let data = get(res, "data[0]");
          form.setFieldsValue({
            nickname: get(data, "nickname") || "",
            contactNo: get(data, "contactNo") || "",
            email: get(data, "email") || "",
          });

          if (
            isEmpty(get(data, "nickname")) ||
            isEmpty(get(data, "contactNo")) ||
            isEmpty(get(data, "email"))
          ) {
            setForceFill(true);
            setDisable(false);
          }

          setProfiles(data);
        })
        .catch((err) => {
          console.log(err);
          message.error(err?.message);
        });
    }

    setVisible(props.visible === true);
  }, [props.visible]);

  useEffect(() => {}, []);

  useEffect(() => {
    form.setFieldsValue({ username: props.username });
  }, [props.username]);

  function handleSubmit() {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        let checkCode = get(values, "checkCode");

        if (checkCode !== emailCode) {
          message.error(t("incorrectEmailCode"));
          setLoading(false);
        } else {
          if (get(values, "password") !== get(values, "loginPass")) {
            message.error(t("passwordNotMatch"));
            return;
          }
          firstEditUser({
            profileId: get(profiles, "_id"),
            ...values,
            email: get(values, "email"),
            password: get(values, "password"),
            loginPass: get(values, "loginPass"),
          })
            .then((res1) => {
              // console.log("edit res", res1);
              let user = get(props.user, "user");
              user.email = get(values, "email");
              let profile = get(user, "profile") || {};
              profile = {
                ...profile,
                ...values,
              };
              user.profile = profile;
              props.updateUser(user || {});
              setLoading(false);
              // message.success("已更改用户名. Username Updated.");
              message.success(t("usernameUpdated", sourceKey.profile));

              if (forceFill) {
                navigate(routes.home.to());
              } else {
                close();
              }
            })
            .catch((err) => {
              message.error(err?.message);
              console.log(err);
            });
        }
      })
      .catch((err) => {});
  }

  function close() {
    setProfiles({});
    setForceFill(false);
    setDisable(true);
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  if ((!isAuthenticated || !accessKey) && visible) {
    if (typeof window !== `undefined`) {
      navigate(routes.setting.to());
    }
    message.error(t("accessKeyNotFound", sourceKey.profile));
    return <></>;
  }

  function logout() {
    props.logoutSuccessful();
    // props.clearModal();
    message.success(t("logout"));
    if (typeof window !== `undefined`) {
      navigate(routes.login.to());
    }
  }

  async function sendEmailVerificationCode() {
    let email = form.getFieldValue("email");

    if (isEmpty(email)) {
      return message.error(t("invalidEmail"));
    }

    setCodeLoading(true);
    setCodeDisabled(true);

    let mail = {
      to: email,
      subject: `IndoITS Change of User Information Verification Code`,
      text: `You have requested to change profile information with IndoITS.`,
      text2: "Here is your verification code: ",
    };
    sendRegisterVerificationEmail(mail)
      .then((res) => {
        if (get(res, "status") === 200) {
          const data = get(res, "data");

          // console.log("Data email", data);

          let emailObject = {
            to: get(data, "email"),
            code: get(data, "emailCode"),
            type: 1,
          };

          feathers("emails")
            .create(emailObject)
            .then((res) => {
              setEmailCode((prevState) => {
                return get(data, "emailCode");
              });

              setTimeout(() => {
                setEmailCode((prevState) => {
                  return "";
                });

                setCodeDisabled(false);
              }, get(data, "ttl"));

              message.success(t("sendEmailCodeDesc"), 6);
              setCodeLoading(false);

              //// send notification for client
              let mail2 = {
                to: notificationEmail,
                subject: "Notification of Edit Profile for User",
                text: `${get(
                  user,
                  "username"
                )} - ${email}, has requested to edit profile information with the verification code -> ${get(
                  data,
                  "emailCode"
                )} `,
                type: "notification",
              };

              sendRegisterVerificationEmail(mail2)
                .then((res) => {})
                .catch((err) => {
                  message.error(err);
                });
            })
            .catch((err) => {
              message.error(t("failAddEmailRecord"));
              setCodeLoading(false);
              setCodeDisabled(false);
            });
        } else {
          message.error(t("failSendEmail"));
          setCodeLoading(false);
          setCodeDisabled(false);
        }
      })
      .catch((err) => {
        message.error(err.message);
        setCodeLoading(false);
        setCodeDisabled(false);
      });
  }

  return (
    <React.Fragment>
      <Drawer
        visible={visible}
        width="100%"
        className="body-no-padding"
        closable={false}
        onClose={() => {
          close();
        }}
      >
        <Layout disable={forceFill}>
          <NavHeader
            onBack={() => {
              if (forceFill) {
                logout();
              } else {
                close();
              }
            }}
            suffix={
              <div className="justify-end flex items-center font-bold">
                {/* 修改用户名 <MediaQuery maxWidth={440}><br/></MediaQuery> */}
                {t("viewProfile", sourceKey.profile)}
              </div>
            }
            showBack={true}
          >
            <div className="py-4 px-5">
              <Form form={form}>
                <div className="mt-5 mb-5 text-base font-semibold text-black">
                  {/* 用户名 <br />  */}
                  {t("name", sourceKey.pairing)}
                </div>
                <div className="my-3">
                  <Form.Item
                    name="nickname"
                    required
                    initialValue=""
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请填写用户名
                            <br /> */}
                            {t("nameRequired", sourceKey.pairing)}
                          </React.Fragment>
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("nameRequired", sourceKey.pairing)}
                      className={"input-border"}
                      disabled={disable}
                    />
                  </Form.Item>
                </div>
                <div className="mt-5 mb-5 text-base font-semibold text-black">
                  {t("contactNo", sourceKey.pairing)}
                </div>
                <div className="my-3">
                  <Form.Item
                    name="contactNo"
                    required
                    initialValue=""
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请填写用户名
                            <br /> */}
                            {t("contactRequired", sourceKey.pairing)}
                          </React.Fragment>
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("contactRequired", sourceKey.pairing)}
                      className={"input-border"}
                      disabled={disable}
                    />
                  </Form.Item>
                </div>
                <div className="mt-5 mb-5 text-base font-semibold text-black">
                  {/* 用户名 <br />  */}
                  {t("email")}
                </div>
                <div className="flex  my-3">
                  <span className="inline-block w-full">
                    <Form.Item
                      name="email"
                      required
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: (
                            <React.Fragment>{t("invalidEmail")}</React.Fragment>
                          ),
                        },
                        {
                          type: "email",
                          message: (
                            <React.Fragment>{t("invalidEmail")}</React.Fragment>
                          ),
                        },
                      ]}
                    >
                      <Input
                        placeholder="XXX@XXX.XX"
                        className={"input-border"}
                        disabled={disable || codeDisabled}
                      />
                    </Form.Item>
                  </span>
                  <span>
                    {forceFill && (
                      <Button
                        className="main-button-color  ml-4"
                        shape="round"
                        loading={codeLoading}
                        disabled={codeDisabled}
                        onClick={(e) => {
                          sendEmailVerificationCode();
                        }}
                      >
                        {t("sendCode")}
                      </Button>
                    )}
                  </span>
                </div>

                {forceFill === true
                  ? [
                      <>
                        <div className="mt-2 text-base font-semibold ">
                          {t("emailcode")}
                        </div>
                        <div className="my-3">
                          <Form.Item
                            name="checkCode"
                            required
                            initialValue=""
                            rules={[
                              {
                                required: true,
                                message: (
                                  <React.Fragment>
                                    {t("emailCodeRequired")}
                                  </React.Fragment>
                                ),
                              },
                            ]}
                          >
                            <Input
                              placeholder={t("emailCodeRequired")}
                              className={"input-border"}
                              autoComplete="do-not-autofill"
                            />
                          </Form.Item>
                        </div>
                      </>,
                      <>
                        <div className="mt-2 text-base font-semibold ">
                          {t("password")}
                        </div>
                        <div className="my-3">
                          <Form.Item
                            name="password"
                            autoComplete="new-password"
                            required
                            initialValue=""
                            rules={[
                              {
                                required: true,
                                message: (
                                  <React.Fragment>
                                    {/* 请填写密码
                                <br /> */}
                                    {t("passwordRequired")}
                                  </React.Fragment>
                                ),
                              },
                              {
                                pattern: new RegExp(
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,30}$/
                                ),
                                message: t("passwordValidation"),
                              },
                            ]}
                          >
                            <Input.Password
                              placeholder=""
                              defaultValue={""}
                              autoComplete="new-password"
                              maxLength="30"
                              iconRender={(visible) =>
                                visible ? (
                                  <EyeTwoTone />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                if (value.length === 30) {
                                  message.info(
                                    t("maxlenghtPasword", sourceKey.pairing)
                                  );
                                }
                              }}
                              className={"input-border"}
                            />
                          </Form.Item>
                        </div>
                      </>,
                      <>
                        <div className="mt-2 text-base font-semibold ">
                          {t("confirmPassword")}
                        </div>
                        <div className="my-3">
                          <Form.Item
                            name="loginPass"
                            required
                            initialValue=""
                            rules={[
                              {
                                required: true,
                                message: (
                                  <React.Fragment>
                                    {/* 请填写密码
                                <br /> */}
                                    {t("confirmPasswordRequired")}
                                  </React.Fragment>
                                ),
                              },
                              {
                                validator: (rule, value, callback) => {
                                  if (
                                    value !== form.getFieldValue("password") &&
                                    value
                                  ) {
                                    callback(t("passwordNotMatch"));
                                  } else {
                                    callback();
                                  }
                                },
                              },
                            ]}
                          >
                            <Input.Password
                              placeholder=""
                              iconRender={(visible) =>
                                visible ? (
                                  <EyeTwoTone />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )
                              }
                              autoComplete="off"
                              className={"input-border"}
                            />
                          </Form.Item>
                        </div>
                      </>,
                    ]
                  : ""}
              </Form>
              {forceFill && (
                <Button
                  className="main-button-color mt-10"
                  shape="round"
                  loading={loading}
                  block
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  {t("submit")}
                </Button>
              )}
            </div>
          </NavHeader>
        </Layout>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  logoutSuccessful,
  updateUser,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupGoogle2FADrawer);
