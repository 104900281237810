import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Drawer, Form, Input, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { navigate } from "gatsby";
import { get, isEmpty } from "lodash";
import forEach from "lodash.foreach";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import patchPassword from "../../../newApi/user/patchPassword";
import patchSecondPassword from "../../../newApi/user/patchSecondPassword";
import {
  logoutSuccessful,
  updateUser,
} from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import { verifyGoogle2faCode } from "../../../utilities/google-2fa-qr";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// markup
const ChangePasswordDrawer = (props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  // const [qrCodeVisible, setQrCodeVisible] = useState(false);
  const [type, setType] = useState("");
  const isAuthenticated = get(props.user, "isAuthenticated");
  const accessKey = get(props.user, "accessKey");
  const getEmail = get(props.user, "user.email");
  const [form] = useForm();
  // const [qrCode, setQrCode] = useState("");
  // const [getCode, setGetCode] = useState("");
  // const [secretKey, setSecretKey] = useState("");
  // const googleBindingKey = get(props.user, "user.ex1");
  const [isLoading, setIsLoading] = useState(false);
  const userId = get(props.user, "user._id");
  const user = get(props, "user.user");

  useEffect(() => {
    setVisible(props.visible === true);

    if (props.visible === true) {
      check2FAStatus();
      setType(props.type);
    }
  }, [props.visible]);

  function check2FAStatus() {
    if (isEmpty(get(user, "secretKey"))) {
      //Google 2FA not set up
      message.loading(t("setUp2FA", sourceKey.asset), 3);
      setTimeout(() => {
        if (props.redirectGoogleAuth) {
          props.redirectGoogleAuth();
        }
      }, 2000);
    }
  }

  function handleSubmit() {
    setIsLoading(true);
    form
      .validateFields()
      .then((values) => {
        values.type = "1";

        if (
          verifyGoogle2faCode(
            get(props.user, "user.secretKey"),
            values.checkCode
          )
        ) {
          let correctValues = {};
          if (type === "second") {
            correctValues = {
              secondPassword: get(values, "pass"),
            };
            patchSecondPassword({
              username: user?.username,
              secondPassword: get(values, "pass"),
            })
              .then((res) => {
                // console.log("res",res);
                message.success(t("success"));
                setIsLoading(false);
                // props.updateUser(get(res, "data") || {});
                close();
              })
              .catch((err) => {
                message.error(err.message);
                setIsLoading(false);
                console.log(err);
              });
          } else {
            correctValues = {
              password: get(values, "pass"),
            };
            patchPassword({
              userId: userId,
              ...correctValues,
            })
              .then((res) => {
                // console.log("Res", res);
                message.success(t("success"));
                setIsLoading(false);
                props.updateUser(get(res, "data[0]") || {});
                close();
              })
              .catch((err) => {
                message.error(err.message);
                setIsLoading(false);
                console.log(err);
              });
          }
          // console.log(correctValues);
        } else {
          message.error(t("invalid2FA", sourceKey.asset));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.error) {
          message.error(err.message);
        } else {
          forEach(get(err, "errorFields"), (item) => {
            message.error(get(item, "errors[0]"));
          });
        }
        setIsLoading(false);
      });
  }

  function close() {
    form.resetFields();
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  if ((!isAuthenticated || !accessKey) && visible) {
    if (typeof window !== `undefined`) {
      navigate(routes.setting.to());
    }
    message.error(t("accessKeyNotFound", sourceKey.profile));
    return <></>;
  }

  return (
    <React.Fragment>
      <Drawer
        visible={visible}
        width="100%"
        className="body-no-padding"
        closable={false}
        onClose={() => {
          close();
        }}
      >
        <Layout>
          <NavHeader
            onBack={() => {
              close();
            }}
            suffix={
              <div className="justify-end flex items-center">
                {/* 修改密码{" "}
                <MediaQuery maxWidth={440}>
                  <br />
                </MediaQuery> */}
                {t("changePassword", sourceKey.profile)}
              </div>
            }
            showBack={true}
          >
            <Spin spinning={isLoading}>
              <div className="py-10 px-5">
                <Form form={form} style={{ color: "black" }}>
                  <Form.Item
                    name="email"
                    style={{ display: "none" }}
                    initialValue={getEmail}
                  ></Form.Item>
                  <div className="mt-5 mb-3 text-base font-semibold">
                    {/* 新密码 <br />  */}
                    {t("newPassword")}
                  </div>
                  <div className="mb-5 text-xs">
                    {/* 密码条件: 8 到 16 个字母（含 1 个小写字母、1 个大写字母和 1
                    个数字） <br /> */}
                    {t("passwordCriteria")}
                  </div>
                  <div className="my-3">
                    <Form.Item
                      name="pass"
                      required
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: (
                            <React.Fragment>
                              {/* 请填写新密码
                              <br /> */}
                              {t("newPasswordRequired")}
                            </React.Fragment>
                          ),
                        },
                        {
                          pattern: new RegExp(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]{8,30}$/
                          ),
                          message: t("passwordValidation"),
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={t("insertPassword")}
                        maxLength="30"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        className={"input-border"}
                      />
                    </Form.Item>
                  </div>

                  <div className="mt-5 mb-5 text-base font-semibold">
                    {/* 确认新密码 <br /> */}
                    {t("confirmNewPassword")}
                  </div>
                  <div className="my-3">
                    <Form.Item
                      name="confirmPass"
                      required
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: (
                            <React.Fragment>
                              {/* 请确认新密码
                              <br /> */}
                              {t("confirmNewRequired")}
                            </React.Fragment>
                          ),
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (value !== form.getFieldValue("pass") && value) {
                              callback(t("passwordNotMatch"));
                            } else {
                              callback();
                            }
                          },
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={t("insertPassword")}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        className={"input-border"}
                      />
                    </Form.Item>
                  </div>

                  <div className="mt-5 mb-5 text-base font-semibold">
                    {/* 谷歌验证码 <br /> */}
                    {t("googleFA", sourceKey.profile)}
                  </div>
                  <div className="my-3">
                    <Form.Item
                      name="checkCode"
                      rules={[
                        {
                          required: true,
                          message: (
                            <React.Fragment>
                              {/* 请填写谷歌验证码
                              <br /> */}
                              {t("insertGoogleFA", sourceKey.profile)}
                            </React.Fragment>
                          ),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("insertGoogleFA", sourceKey.profile)}
                        className={"input-border"}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="type"
                    style={{ display: "none" }}
                    initialValue={1}
                  ></Form.Item>
                </Form>
                <Button
                  className="main-button-color mt-10"
                  shape="round"
                  block
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  {t("submit")}
                </Button>
              </div>
            </Spin>
          </NavHeader>
        </Layout>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  logoutSuccessful,
  updateUser,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordDrawer);
