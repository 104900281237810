import { ToolOutlined } from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { Divider, message } from "antd";
import { navigate } from "gatsby";
import localStorage from "local-storage";
import { filter, get, isEmpty } from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Textfit } from "react-textfit";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getUserByObjId from "../../../newApi/user/getUserByObjId";
import { logoutSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import { languages } from "../../../utilities/profile";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import ChangePasswordDrawer from "../components/ChangePasswordDrawer";
import ChangeUsernameDrawer from "../components/ChangeUsernameDrawer";
import SelectLanguageModal from "../components/SelectLanguageModal";
import SetupGoogle2FADrawer from "../components/SetupGoogle2FADrawer";

// markup
const SettingPage = (props) => {
  const isAuthenticated = get(props, "user.isAuthenticated");
  const location = useLocation();
  const { t } = useTranslation();
  const [google2FAVisible, setGoogle2FAVisible] = useState(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [changeUsernameVisible, setChangeUsernameVisible] = useState(false);
  const [changePasswordType, setChangePasswordType] = useState("password");
  const [secretKey, setSecretKey] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState({});
  const [selectVisible, setSelectVisible] = useState(false);
  const [user, setUser] = useState(get(props, "user.user"));
  const userId = get(props, "user.user._id");

  const accessKey = get(props.user, "accessKey");
  let locales = "";
  locales = localStorage.get("locale");

  useEffect(() => {
    if (isEmpty(locales)) {
      let english = filter(languages, (item, index) => {
        return get(item, "value") === "en";
      });
      english = english[0];
      setCurrentLanguage(english);
    } else {
      let selectedLanguage = filter(languages, (item, index) => {
        return get(item, "value") === locales;
      });
      selectedLanguage = selectedLanguage[0];
      setCurrentLanguage(selectedLanguage);
    }

    let query = queryString.parse(location.search);
    if (!isEmpty(query)) {
      if (get(query, "type")) {
        let type = get(query, "type");
        if (type === "2FA") {
          setGoogle2FAVisible(true);
        } else if (type === "forceFill") {
          setChangeUsernameVisible(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    getData();
  }, [props.user]);

  // console.log("props", props);

  function redirectGoogleAuth() {
    setChangePasswordVisible(false);
    setGoogle2FAVisible(true);
  }
  function getData() {
    getUserByObjId(accessKey, userId).then((res) => {
      setUser(get(res, "data[0]"));
      setSecretKey(get(res, "data[0].secretKey"));
    });
  }

  if (!isAuthenticated && typeof window !== `undefined`) {
    navigate(routes.login.to());
    message.error(t("loginProceed", sourceKey.profile));
    return <></>;
  }

  return (
    <React.Fragment>
      <Layout>
        <NavHeader
          suffix={
            <div className="justify-end flex items-center">
              {/* 个人设置{" "}
              <MediaQuery maxWidth={440}>
                <br />
              </MediaQuery> */}

              {t("profileSetting", sourceKey.profile)}
            </div>
          }
          showBack={true}
          onBack={() => {
            navigate(routes.profile.to());
          }}
        >
          <div className="px-3 pb-5 pt-10">
            <div className="flex justify-between items-center my-3 px-2">
              <span className="inline-block w-1/3 mr-4">
                {/* 邮箱<br></br> */}
                {t("email")}
              </span>
              <Textfit className="inline-block w-2/3 text-black input-border p-3 bg-gray-100">
                {get(user, "email")}
              </Textfit>
            </div>
            <div className="flex justify-between items-center my-3 px-2">
              <span className="inline-block w-1/3 mr-4 ">
                {/* 用户ID
                <br /> */}
                {t("userId", sourceKey.profile)}
              </span>
              <Textfit className="inline-block  w-2/3 input-border p-3 bg-gray-100 text-black">
                {get(user, "referralCode")}
              </Textfit>
            </div>
            <div className="flex justify-between items-center my-3 px-2">
              <span className="inline-block w-1/3 mr-4 ">
                {/* 用户ID
                <br /> */}
                {t("username", sourceKey.profile)}
              </span>
              <Textfit className="inline-block  w-2/3 input-border p-3 bg-gray-100 text-black">
                {get(user, "username")}
              </Textfit>
            </div>
            {/* <div className="flex justify-between items-center my-3 px-2">
              <span className="inline-block w-1/3 mr-4">
               
                {t("username", sourceKey.profile)}
              </span>
              <span className="flex justify-between items-center w-2/3 input-border p-3">
                <Textfit className="inline-block mr-4 ">
                  {get(user, "username")}
                </Textfit>
                <ToolOutlined
                  className=" cursor-pointer"
                  onClick={(e) => {
                    setChangeUsernameVisible(true);
                  }}
                />
              </span>
            </div> */}
            <Divider />
            <div className="flex items-center my-3 px-2">
              <span className="inline-block w-1/3 mr-3">
                {t("viewProfile", sourceKey.profile)}
              </span>
              <span
                className="flex w-2/3 justify-center input-border p-2  cursor-pointer"
                onClick={(e) => {
                  setChangeUsernameVisible(true);
                }}
              >
                <ToolOutlined
                  style={{
                    fontSize: 20,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </span>
            </div>
            <div className="flex items-center my-3 px-2">
              <span className="inline-block w-1/3 mr-3">
                {/* 谷歌验证
                <br />  */}
                {t("googleFA", sourceKey.profile)}
              </span>
              <span
                className="flex w-2/3 justify-center input-border p-2  cursor-pointer"
                onClick={(e) => {
                  setGoogle2FAVisible(true);
                }}
              >
                <ToolOutlined
                  style={{
                    fontSize: 20,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </span>
            </div>
            <div className="flex items-center my-3 px-2">
              <span className="inline-block w-1/3 mr-3">
                {/* 登入密码
                <br />  */}
                {t("loginPassword", sourceKey.profile)}
              </span>
              <span
                className="flex w-2/3  justify-center  input-border p-2  cursor-pointer"
                onClick={(e) => {
                  setChangePasswordVisible(true);
                  setChangePasswordType("password");
                }}
              >
                <ToolOutlined
                  style={{
                    fontSize: 20,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </span>
            </div>
            <div className="flex items-center my-3 px-2">
              <span className="inline-block w-1/3 mr-3">
                {/* 第二密码
                <br />  */}
                {t("secondPassword", sourceKey.profile)}
              </span>
              <span
                className="flex w-2/3  justify-center  input-border p-2  cursor-pointer"
                onClick={(e) => {
                  setChangePasswordVisible(true);
                  setChangePasswordType("second");
                }}
              >
                <ToolOutlined
                  style={{
                    fontSize: 20,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </span>
            </div>

            <Divider />
            <div className="flex justify-between items-center my-3 px-2">
              <span className="inline-block w-1/3 mr-4">
                {/* 语言
                <br /> */}
                {t("language", sourceKey.profile)}
              </span>
              <span
                className="flex justify-center items-center w-2/3 input-border p-3 cursor-pointer"
                onClick={() => {
                  setSelectVisible(true);
                }}
              >
                {!isEmpty(currentLanguage)
                  ? get(currentLanguage, "title")
                  : "English"}
              </span>
            </div>
          </div>
        </NavHeader>

        <SelectLanguageModal
          visible={selectVisible}
          onClose={() => {
            setSelectVisible(false);
          }}
          user={user}
        />

        <ChangeUsernameDrawer
          visible={changeUsernameVisible}
          username={get(props.user, "user.username")}
          onClose={() => {
            setChangeUsernameVisible(false);
            setChangePasswordType("password");
            getData();
          }}
        />

        <SetupGoogle2FADrawer
          visible={google2FAVisible}
          secretKey={get(props.user, "user.secretKey")}
          onClose={() => {
            setGoogle2FAVisible(false);
            navigate(routes.setting.to());
            getData();
          }}
        ></SetupGoogle2FADrawer>

        <ChangePasswordDrawer
          redirectGoogleAuth={redirectGoogleAuth}
          visible={changePasswordVisible}
          type={changePasswordType}
          onClose={() => {
            setChangePasswordVisible(false);
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  logoutSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
